import React from 'react';
import { graphql, navigate, withPrefix } from 'gatsby';
import { getUserLangKey } from 'ptz-i18n';

const RedirectProcesses = props => {
  if (typeof window !== 'undefined') {
    const { langs, defaultLangKey } = props.data.site.siteMetadata;
    const langKey = getUserLangKey(langs, defaultLangKey);
    const homeUrl = withPrefix(`/${langKey}/processes`);
    navigate(homeUrl);
  }

  return <div />;
};

export default RedirectProcesses;

export const PROCESSES_LANG_QUERY = graphql`
  query PROCESSES_LANG_QUERY {
    site {
      siteMetadata {
        defaultLangKey
        langs
      }
    }
  }
`;
